// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dates-js": () => import("./../../../src/pages/dates.js" /* webpackChunkName: "component---src-pages-dates-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-retreats-js": () => import("./../../../src/pages/retreats.js" /* webpackChunkName: "component---src-pages-retreats-js" */),
  "component---src-pages-yoga-js": () => import("./../../../src/pages/yoga.js" /* webpackChunkName: "component---src-pages-yoga-js" */),
  "component---src-pages-yogaatthewater-js": () => import("./../../../src/pages/yogaatthewater.js" /* webpackChunkName: "component---src-pages-yogaatthewater-js" */)
}

